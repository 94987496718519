<template>
  <div class="loader-container">
    <v-row align="center" justify="center" class="fill-height">
      <v-col cols="auto">
        <v-overlay v-if="type === 'overlay'">
          <v-progress-circular
            indeterminate
            size="100"
          />
        </v-overlay>
        <v-progress-circular
          v-if="type === 'single'"
          indeterminate
          color="primary"
          size="100"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Loader',

  props: {
    type: {
      type: String,
      default: () => 'overlay',
      validator: (value) => {
        return ['overlay', 'single'].indexOf(value) !== -1
      }
    }
  }
}
</script>

<style>
</style>
